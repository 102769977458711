import "./AppContainer.css";

import { useAuthStateContext } from "../contexts/AuthStateContext";
import { SaveOneDropOneGamePage } from "../pages/SaveOneDropOneGamePage";
import { LoginPage } from "../pages/LoginPage";

const AppContainer = (): JSX.Element => {
  const authState = useAuthStateContext();

  return (
    <div>
      <h1>Save One Drop One</h1>
      {!authState ? <LoginPage /> : <SaveOneDropOneGamePage />}
    </div>
  );
};

export default AppContainer;
