import React, { Reducer, createContext, useContext, useReducer } from "react";

type AuthState = {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiresAt: string;
};

type AuthActionType = "login" | "logout";

type AuthAction = {
  type: AuthActionType;
  authState?: AuthState;
};

const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthStateDispatchContext = createContext<React.Dispatch<AuthAction>>(
  () => {
    /* Do nothing. */
  }
);

export function useAuthStateContext(): AuthState | undefined {
  return useContext(AuthStateContext);
}

export function useAuthStateDispatchContext(): React.Dispatch<AuthAction> {
  return useContext(AuthStateDispatchContext);
}

function authStateReducer(
  authState: AuthState | undefined,
  action: AuthAction
): AuthState | undefined {
  switch (action.type) {
    case "login": {
      if (!action.authState) {
        throw Error("Auth state must not be undefined for login action.");
      }

      return action.authState;
    }
    case "logout": {
      return undefined;
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function AuthStateProvider({
  children
}: {
  children: React.ReactElement;
}): JSX.Element {
  const [authState, dispatch] = useReducer<
    Reducer<AuthState | undefined, AuthAction>
  >(authStateReducer, undefined);

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthStateDispatchContext.Provider value={dispatch}>
        {children}
      </AuthStateDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}
