import "./TrackComparisonContainer.css";

import { useEffect, useState } from "react";

import { Track } from "../model/Track";
import { usePlaylistPickerContext } from "../contexts/PlaylistPickerContext";
import { useAuthStateContext } from "../contexts/AuthStateContext";
import { getPlaylistItems } from "../spotify/PlaylistItemProvider";
import { PlaylistItem } from "../model/PlaylistItem";
import { shuffleArray } from "../common/Utils";
import { Playlist } from "../model/Playlist";

import TrackCard from "./TrackCard";

export function TrackComparisonContainer(): JSX.Element {
  const authState = useAuthStateContext();

  const { selectedPlaylistId, playlists } = usePlaylistPickerContext();

  const [tracks, setTracks] = useState<Track[]>([]);

  const [firstTrackIndex, setFirstTrackIndex] = useState<number>(0);
  const [secondTrackIndex, setSecondTrackIndex] = useState<number>(1);

  const getPlaylistById = (
    playlistId: string,
    playlists: Playlist[]
  ): Playlist | null => {
    const playlist = playlists.find((playlist) => playlist.id === playlistId);

    return playlist === undefined ? null : playlist;
  };

  const getSelectedPlaylistTracksById = async (
    selectedPlaylistId: string,
    accessToken: string
  ): Promise<Track[]> => {
    const playlistItems = await getPlaylistItems(
      accessToken,
      selectedPlaylistId
    );
    const selectedPlaylistTracks = playlistItems
      .map((playlistItem: PlaylistItem) => {
        return playlistItem.track;
      })
      .filter((track: Track) => {
        return track.preview_url != null;
      });

    // Shuffle Tracks
    shuffleArray(selectedPlaylistTracks);

    return selectedPlaylistTracks;
  };

  async function updatePlaylistTracks(
    selectedPlaylistId: string,
    accessToken: string
  ) {
    const selectedPlaylistTracks = await getSelectedPlaylistTracksById(
      selectedPlaylistId,
      accessToken
    );

    setTracks(selectedPlaylistTracks);
  }

  useEffect(() => {
    if (!authState) {
      throw Error("Empty access token while updating playlist tracks.");
    }

    if (selectedPlaylistId) {
      // Only update tracks if there is a selected playlist.
      updatePlaylistTracks(selectedPlaylistId, authState.accessToken);
    }
  }, [selectedPlaylistId]);

  useEffect(() => {
    // When the tracks change, reset the track indices.
    setFirstTrackIndex(0);
    setSecondTrackIndex(1);
  }, [tracks]);

  const isPrevEnabled = (
    firstTrackIndex: number,
    secondTrackIndex: number
  ): boolean => {
    return firstTrackIndex - 2 >= 0 && secondTrackIndex - 2 >= 1;
  };

  const isNextEnabled = (
    firstTrackIndex: number,
    secondTrackIndex: number
  ): boolean => {
    return (
      firstTrackIndex + 2 <= tracks.length - 2 &&
      secondTrackIndex + 2 <= tracks.length - 1
    );
  };

  const showPrevTrack = () => {
    if (isPrevEnabled(firstTrackIndex, secondTrackIndex)) {
      setFirstTrackIndex(firstTrackIndex - 2);
      setSecondTrackIndex(secondTrackIndex - 2);
    }
  };

  const showNextTrack = () => {
    if (isNextEnabled(firstTrackIndex, secondTrackIndex)) {
      setFirstTrackIndex(firstTrackIndex + 2);
      setSecondTrackIndex(secondTrackIndex + 2);
    }
  };

  function renderPlaylistName() {
    const noSelectedPlaylistHeading = (
      <h2>No selected playlist. Please select a playlist.</h2>
    );

    if (!selectedPlaylistId) {
      return noSelectedPlaylistHeading;
    }

    const selectedPlaylist = getPlaylistById(selectedPlaylistId, playlists);

    if (!selectedPlaylist) {
      return noSelectedPlaylistHeading;
    }

    return <h2>{selectedPlaylist.name}</h2>;
  }

  const renderTracks = () => {
    // TODO: Fix styling for one track
    if (tracks.length === 1) {
      return (
        <div>
          <TrackCard track={tracks[firstTrackIndex]} />
        </div>
      );
    } else if (tracks.length > 1) {
      return (
        <div className="track-card-container">
          <TrackCard track={tracks[firstTrackIndex]} />
          <TrackCard track={tracks[secondTrackIndex]} />
        </div>
      );
    }

    return (
      <div>
        No tracks in the selected playlist. Try selecting a different playlist.
      </div>
    );
  };

  return (
    <>
      {renderPlaylistName()}
      {renderTracks()}
      <h3>{firstTrackIndex / 2 + 1}</h3>
      <button
        disabled={!isPrevEnabled(firstTrackIndex, secondTrackIndex)}
        onClick={showPrevTrack}
      >
        Previous
      </button>
      <button
        disabled={!isNextEnabled(firstTrackIndex, secondTrackIndex)}
        onClick={showNextTrack}
      >
        Next
      </button>
    </>
  );
}
