import { PlaylistItem } from "../model/PlaylistItem";
import { PlaylistItems } from "../model/PlaylistItems";

import { SPOTIFY_BASE_URL } from "./Constants";

export const getPlaylistItems = async (
  token: string,
  playlistId: string
): Promise<PlaylistItem[]> => {
  // TODO: deal with limit on the number of playlist items returned. Default is 20.
  const urlSearchParams = new URLSearchParams();

  // TODO: deal preview URLs being null https://developer.spotify.com/documentation/general/guides/track-relinking-guide/
  const url = `${SPOTIFY_BASE_URL}/playlists/${playlistId}/tracks?${urlSearchParams.toString()}`;

  const data = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  // TODO: deal with errors
  const playlistItems: PlaylistItems = (await data.json()) as PlaylistItems;

  return playlistItems.items;
};
