// Disable no-explicit-any rule as this function can be used for any type of array.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shuffleArray = (array: any[]): void => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const truncateString = (str: string, length: number): string => {
  if (str.length <= length) {
    return str;
  }

  return str.substring(0, length) + "\u2026";
};
