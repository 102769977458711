import "./SaveOneDropOneGamePage.css";

import { TrackComparisonContainer } from "../components/TrackComparisonContainer";
import { useAuthStateDispatchContext } from "../contexts/AuthStateContext";
import { PlaylistPickerProvider } from "../contexts/PlaylistPickerContext";
import { PlaylistPicker } from "../components/PlaylistPicker";

export const SaveOneDropOneGamePage = (): JSX.Element => {
  const authStateDispatch = useAuthStateDispatchContext();

  const logout = () => {
    authStateDispatch({
      type: "logout"
    });
  };

  return (
    <PlaylistPickerProvider>
      <div>
        <button onClick={logout}>Logout</button>
        <PlaylistPicker />
        <TrackComparisonContainer />
      </div>
    </PlaylistPickerProvider>
  );
};
