import React, { Reducer, createContext, useContext, useReducer } from "react";

import { Playlist } from "../model/Playlist";

type PlaylistPickerState = {
  playlists: Playlist[];
  selectedPlaylistId?: string;
};

const defaultPlaylistPickerState = {
  playlists: [],
  selectedPlaylist: undefined
};

type PlaylistPickerActionType =
  | "setInitialPlaylists"
  | "updateSelectedPlaylistId";

type PlaylistPickerAction = {
  type: PlaylistPickerActionType;
  playlists?: Playlist[];
  selectedPlaylistId?: string;
};

const PlaylistPickerContext = createContext<PlaylistPickerState>(
  defaultPlaylistPickerState
);
const PlaylistPickerDispatchContext = createContext<
  React.Dispatch<PlaylistPickerAction> | undefined
>(undefined);

export function usePlaylistPickerContext(): PlaylistPickerState {
  return useContext(PlaylistPickerContext);
}

export function usePlaylistPickerDispatchContext():
  | React.Dispatch<PlaylistPickerAction>
  | undefined {
  return useContext(PlaylistPickerDispatchContext);
}

function copyPlaylistPickerState(
  state: PlaylistPickerState
): PlaylistPickerState {
  return {
    playlists: [...state.playlists],
    selectedPlaylistId: state.selectedPlaylistId
  };
}

function playlistPickerReducer(
  state: PlaylistPickerState,
  action: PlaylistPickerAction
): PlaylistPickerState {
  switch (action.type) {
    case "setInitialPlaylists": {
      if (!action.playlists) {
        throw Error(
          "playlists must not be undefined for updatePlaylists action."
        );
      }

      return {
        playlists: action.playlists,
        selectedPlaylistId:
          action.playlists.length > 0 ? action.playlists[0].id : undefined
      };
    }
    case "updateSelectedPlaylistId": {
      return {
        ...copyPlaylistPickerState(state),
        selectedPlaylistId: action.selectedPlaylistId
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function PlaylistPickerProvider({
  children
}: {
  children: React.ReactElement;
}): JSX.Element {
  const [state, dispatch] = useReducer<
    Reducer<PlaylistPickerState, PlaylistPickerAction>
  >(playlistPickerReducer, defaultPlaylistPickerState);

  return (
    <PlaylistPickerContext.Provider value={state}>
      <PlaylistPickerDispatchContext.Provider value={dispatch}>
        {children}
      </PlaylistPickerDispatchContext.Provider>
    </PlaylistPickerContext.Provider>
  );
}
