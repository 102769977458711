import { generateCodeChallenge, generateRandomString } from "../common/Auth";

const CLIENT_ID = "55db8dc607824e019f3a380f33a80557";
const ACCOUNTS_ENDPOINT = "https://accounts.spotify.com";

export type AuthorizeEndpointInputs = {
  endpointUrl: string;
  codeVerifier: string;
};

export type SpotifyTokenResponse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};

const getRedirectUri = (hostname: string): string => {
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return "http://localhost:3000/";
  } else if (hostname === "transcendent-swan-68d295.netlify.app") {
    return "https://transcendent-swan-68d295.netlify.app/";
  } else if (hostname === "saveonedropone.com") {
    return "https://saveonedropone.com/";
  }

  // TODO: return maybe original href
  return "";
};

export const getAuthorizeEndpointInputs = async (
  hostname: string,
  state: string
): Promise<AuthorizeEndpointInputs> => {
  const codeVerifier = generateRandomString(64);
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  const authorizeEndpointParams = new URLSearchParams({
    client_id: CLIENT_ID,
    redirect_uri: getRedirectUri(hostname),
    response_type: "code",
    scope: "playlist-read-private",
    code_challenge_method: "S256",
    code_challenge: codeChallenge,
    state
  });

  const endpointUrl = `${ACCOUNTS_ENDPOINT}/authorize?${authorizeEndpointParams.toString()}`;

  return {
    endpointUrl,
    codeVerifier
  };
};

const addThrowErrorToFetch = async (
  // TODO: fix with correct type
  response: any // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<SpotifyTokenResponse> => {
  if (response.ok) {
    return response.json();
  }

  // TODO: investigate warning and fix
  // eslint-disable-next-line no-throw-literal
  throw { response, error: await response.json() };
};

export const exchangeCodeForToken = async (
  code: string,
  codeVerifier: string,
  hostname: string
): Promise<SpotifyTokenResponse> => {
  const response = await fetch(`${ACCOUNTS_ENDPOINT}/api/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    },
    body: new URLSearchParams({
      client_id: CLIENT_ID,
      grant_type: "authorization_code",
      code,
      redirect_uri: getRedirectUri(hostname),
      code_verifier: codeVerifier
    })
  });

  return await addThrowErrorToFetch(response);
};
