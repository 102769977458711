import { useEffect } from "react";

import { generateRandomString } from "../common/Auth";
import { useAuthStateDispatchContext } from "../contexts/AuthStateContext";
import {
  exchangeCodeForToken,
  getAuthorizeEndpointInputs
} from "../spotify/Authorization";

export function LoginPage(): JSX.Element {
  const authStateDispatch = useAuthStateDispatchContext();

  const exchangeTokenAndShowPlaylists = async (code: string): Promise<void> => {
    const codeVerifier = localStorage.getItem("code_verifier");

    if (codeVerifier) {
      const data = await exchangeCodeForToken(
        code,
        codeVerifier,
        window.location.hostname
      );

      window.localStorage.removeItem("code_verifier");

      // TODO: Handle errors of repsonse
      // TODO: Handle token refresh
      const t = new Date();
      const expiresAt = t
        .setSeconds(t.getSeconds() + data.expires_in)
        .toString();

      authStateDispatch({
        type: "login",
        authState: {
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          accessTokenExpiresAt: expiresAt
        }
      });

      // clear search query params in the url
      window.history.replaceState({}, document.title, "/");
    }
  };

  useEffect(() => {
    // TODO: Handle errors
    const args = new URLSearchParams(window.location.search);
    const code = args.get("code");
    const state = args.get("state");

    const authRequestState = window.localStorage.getItem("auth_request_state");

    if (code && state && authRequestState && state === authRequestState) {
      window.localStorage.removeItem("auth_request_state");

      exchangeTokenAndShowPlaylists(code);
    } else {
      // TODO: Show Error Dialog
      // clear search query params in the url
      window.history.replaceState({}, document.title, "/");
      window.localStorage.removeItem("auth_request_state");
    }
  }, []);

  const login = async () => {
    const state = generateRandomString(16);
    window.localStorage.setItem("auth_request_state", state);

    const { endpointUrl, codeVerifier } = await getAuthorizeEndpointInputs(
      window.location.hostname,
      state
    );

    window.localStorage.setItem("code_verifier", codeVerifier);

    // Due to TypeScript issues https://github.com/microsoft/TypeScript/issues/48949
    (window as Window).location = endpointUrl;
  };

  return <button onClick={login}>Login</button>;
}
