import { useEffect } from "react";

import { getCurrentUserPlaylists } from "../spotify/PlaylistProvider";
import { useAuthStateContext } from "../contexts/AuthStateContext";
import {
  usePlaylistPickerContext,
  usePlaylistPickerDispatchContext
} from "../contexts/PlaylistPickerContext";

export const PlaylistPicker = (): JSX.Element => {
  const authState = useAuthStateContext();

  const { playlists, selectedPlaylistId } = usePlaylistPickerContext();
  const playlistPickerDispatch = usePlaylistPickerDispatchContext();

  if (!playlistPickerDispatch) {
    throw Error("playlistPickerDispatch must not be undefined.");
  }

  const getAndShowPlaylists = async (accessToken: string) => {
    const playlists = await getCurrentUserPlaylists(accessToken);

    playlistPickerDispatch({
      type: "setInitialPlaylists",
      playlists
    });
  };

  useEffect(() => {
    if (authState) {
      getAndShowPlaylists(authState.accessToken);
    } else {
      throw Error("Empty access token");
    }
  }, [authState]);

  const onPlaylistChanged = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlaylistId = e.target.value;

    if (!selectedPlaylistId) {
      throw Error("selectedPlaylistId is empty.");
    }

    playlistPickerDispatch({
      type: "updateSelectedPlaylistId",
      selectedPlaylistId
    });
  };

  return (
    <>
      {playlists.length > 0 && selectedPlaylistId ? (
        <div className="choose-playlist">
          <label>Choose a playlist: </label>
          <select value={selectedPlaylistId} onChange={onPlaylistChanged}>
            {playlists.map((playlist) => {
              return (
                <option key={playlist.id} value={playlist.id}>
                  {playlist.name}
                </option>
              );
            })}
          </select>
        </div>
      ) : (
        <h2>No playlists available. Please add a playlist.</h2>
      )}
    </>
  );
};
