import { Playlist } from "../model/Playlist";
import { Playlists } from "../model/Playlists";

export const getCurrentUserPlaylists = async (
  token: string
): Promise<Playlist[]> => {
  // TODO: deal with limit on the number of playlists returned. Default is 20.
  const urlSearchParams = new URLSearchParams();

  const url = `https://api.spotify.com/v1/me/playlists?${urlSearchParams.toString()}`;

  const data = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  // TODO: deal with errors
  const playlists: Playlists = (await data.json()) as Playlists;

  return playlists.items;
};
