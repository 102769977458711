import "./App.css";
import AppContainer from "./components/AppContainer";
import { AuthStateProvider } from "./contexts/AuthStateContext";

function App(): JSX.Element {
  return (
    <div className="App">
      <AuthStateProvider>
        <AppContainer />
      </AuthStateProvider>
    </div>
  );
}

export default App;
