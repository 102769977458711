import "./TrackCard.css";

import { truncateString } from "../common/Utils";
import { TrackArtist } from "../model/TrackArtist";
import { Track } from "../model/Track";

type TrackCardProps = {
  track: Track;
};

const ARTIST_CHAR_LIMIT = 100;

const TrackCard: React.FC<TrackCardProps> = (props: TrackCardProps) => {
  const { track } = props;

  const artists = truncateString(
    track.artists
      .map((artist: TrackArtist) => {
        return artist.name;
      })
      .join(", "),
    ARTIST_CHAR_LIMIT
  );

  const onImgClick = () => {
    const audioElem = document.getElementById(
      "audio_" + track.id
    ) as HTMLAudioElement;

    if (audioElem != null) {
      if (audioElem.paused) {
        audioElem.play();
      } else {
        audioElem.pause();
      }
    }
  };

  const imgAltText = `Album Cover of ${track.album.name}`;

  return (
    <div className="track-card">
      <img
        alt={imgAltText}
        src={track.album.images[0].url}
        width="100%"
        onClick={onImgClick}
      />
      <audio key={track.id} id={"audio_" + track.id} controls>
        <source src={track.preview_url} type="audio/mpeg" />
      </audio>
      <h3>{track.name}</h3>
      <h3>{artists}</h3>
    </div>
  );
};

export default TrackCard;
